import React, { useEffect } from "react"
import styled from "styled-components"
import { ExploreMore } from "../Section"
import PrimaryButton from "../Button/PrimaryButton"

const ReviewGrid = styled.div`
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 60px;
  padding: 30px 20px;
  @media(min-width:768px){
    padding: 40px 30px;
  }
  @media(min-width:992px){
    padding: 60px 30px;
  }
`
const ProductReviewWidget = () => {
    useEffect(()=>{
        const script = document.createElement('script');
        script.src = "https://cdn.trustindex.io/loader.js?9b16aad155f1699e6306cfbf603";
        script.async = true;
        script.defer = true;
        document.getElementById("product-widget-script").appendChild(script)
     },[])
    return(
        <ReviewGrid>
          <div id="product-widget-script"></div>
            <ExploreMore>
            <a href="https://www.google.com/search?q=coast+to+coast+carports&oq=Coast+To+Coast+Carports&aqs=chrome.0.35i39i650j46i175i199i512j0i512l3j69i60l2j69i61.317j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54ab36eb29e3de6b:0xe9692b811fa91bb4,3,,,," target="_blank" rel="noreferrer" aria-label="LEAVE US A REVIEW" ><PrimaryButton text="LEAVE US A REVIEW" /></a>
            </ExploreMore>
        </ReviewGrid>
    )
}

export default ProductReviewWidget
