import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"

export const Modal = styled.div`
  display: none;
  @warn ${props => props.display};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
	align-items: flex-end;
`

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  ${BreakpointUp.lg`
		top: 20px;
		right: 20px;
		width: 20px;
		height: 20px;
	`}
  svg {
    width: 16px;
    height: 16px;
    fill: #999;
  }
  &:hover {
    svg {
      fill: #fc0002;
    }
  }

  &.product-select-cancel {
    top: 28px;
  }
`
CloseButton.defaultProps = {}

export const ModalDialog = styled.div`
  position: relative;
  width: auto;
  pointer-events: none;
`
ModalDialog.defaultProps = {}

export const ModalContent = styled.div`
  background-color: ${props => props.bgColor};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
`
ModalContent.defaultProps = {
  bgColor: "#fff",
}

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 40px 20px 20px;
  ${BreakpointUp.md`
		padding:60px 30px 30px;
	`}
  ${BreakpointUp.lg`
		padding: 60px;
	`}
`
ModalBody.defaultProps = {}

export const ModalTitle = styled.div`
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  margin: 0 0 15px;
  text-align: center;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`
ModalTitle.defaultProps = {}

export const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
`
ModalFooter.defaultProps = {}

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  inset: 0px;
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: 0.8;
  }
`
