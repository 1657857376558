import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const DefaultOutlineBtn = styled(BaseButton)`
	background:#fff;
	border: 1px solid #D3D3D3;
	color:#000;	
	&:hover{	
		border-color: #000;
		> svg{
			fill:#000;
		}
	}
`

const DefaultOutlineButton = (props) => {
	const { icon, text } = props;
	return(
		<DefaultOutlineBtn className='btn'>
			<span className='icon'>{icon}</span><span className='text'>{text}</span>
		</DefaultOutlineBtn>
	)
}

export default DefaultOutlineButton