import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { WidthIcon, LenghtIcon, HeightIcon, PhoneIcon, QuoteIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"
import { GatsbyImage } from "gatsby-plugin-image"
import commaNumber from 'comma-number'
import PrimaryButton from "../Button/PrimaryButton"
import DefaultButton from "../Button/DefaultButton"


const CardProduct = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const CardProductHeading = styled.div`
  padding: 20px;
`
const CardProductTitle = styled.h3`
  margin: 0 0 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const CardProductFigure = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 30px;
  align-items: center;
  z-index: 1;
`
const CardProductBody = styled.div`
  width: 100%;
  position: relative;
  &:before {
    content: "";
    background: #fff;
    border: 1px solid #ebedf3;
    position: absolute;
    width: 100%;
    left: 0;
    top: -25%;
    bottom: 0;
    right: 0;
  }
`
const CardProductContent = styled.div`
  position: relative;
  z-index: 1;
`
const ProductSize = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #ebedf3;
  border-bottom: 1px solid #ebedf3;
  margin: 0;
  padding: 20px;
`
const ProductSku = styled.div`
  color: #999;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 10px;

  span {
    font-weight: 500;
  }
`
const ProductItemSize = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  .icon {
    display: inline-flex;
    svg {
      width: 40px;
      ${BreakpointUp.sm`		
                width:auto;
            `}
    }
    + .text {
      margin-left: 5px;
    }
  }
  .text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    small {
      color: #999;
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      + strong {
        margin-left: 5px;
      }
    }
    strong {
      display: block;
      color: #000;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
`

const Price = styled.div`
  small {
    color: #999;
    font-size: 20px;
  }
  strong {
    font-size: 24px;
    color: #fc0002;
    font-weight: 700;
  }
`
const HelpBlock = styled.div`
  color: #999;
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
  a {
    color: #fc0002;
    font-size: 13px;
    line-height: 18px;
    display: inline-flex;
    align-items: center;
    svg {
      fill: #fc0002;
    }
    .text {
      line-height: 18px;
      + .icon {
        margin-left: 5px;
      }
    }
    .icon {
      line-height: 18px;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      svg {
        width: 12px;
      }
    }
    &:hover,
    &:focus {
      color: #0b619b;
      svg {
        fill: #0b619b;
      }
    }
  }
`
const ButtonToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;
  padding: 20px;
  flex-wrap: wrap;
  
  .btn {
    margin: 10px;
  }
`

const VerticalProductCard = ({ productData,openQuoteModal }) => {
  productData = (productData.node !== undefined) ? productData.node : productData;
  return (
    <CardProduct className="card">
      <CardProductFigure className="card-img">
        <Link to ={productData.url}>
          <GatsbyImage
            image={productData.productImages.gatsbyImageData}
            alt={productData.productImages.title}
            />
        </Link>
      </CardProductFigure>
      <CardProductBody className="card-body">
        <CardProductContent className="card-content">
          <CardProductHeading>
            <ProductSku><span>SKU No:</span> {productData.productSku}</ProductSku>
            <Link to={productData.url}>
              <CardProductTitle className="card-title">
                {productData.productName}
              </CardProductTitle>
            </Link>
            <Price>
              <small>Starting Price :</small>{" "}
              <strong>${commaNumber(productData.price)}*</strong>
            </Price>
          </CardProductHeading>
          <ProductSize className="measurement">
            <ProductItemSize>
              <span className="icon">
                <WidthIcon />
              </span>
              <span className="text">
                <small>Width</small>
                <strong>{productData.width}</strong>
              </span>
            </ProductItemSize>
            <ProductItemSize>
              <span className="icon">
                <LenghtIcon />
              </span>
              <span className="text">
                <small>Length</small>
                <strong>{productData.length}</strong>
              </span>
            </ProductItemSize>
            <ProductItemSize>
              <span className="icon">
                <HeightIcon />
              </span>
              <span className="text">
                <small>Height</small>
                <strong>{productData.height}</strong>
              </span>
            </ProductItemSize>
          </ProductSize>
          <ButtonToolbar>
            <a href="tel:8666817846" aria-label="(866) 681-7846">
              <PrimaryButton icon={<PhoneIcon />} text="(866) 681-7846" />
            </a>
            <button type="button" onClick={openQuoteModal}>
              <DefaultButton icon={<QuoteIcon />} text="Request A Quote" />
            </button>
          </ButtonToolbar>
        </CardProductContent>
      </CardProductBody>
      <Link to ={productData.url}>
        <HelpBlock>
          Click here to view the complete building inclusions :{" "}
        </HelpBlock>
      </Link> 
    </CardProduct>  
  )
}

export default VerticalProductCard
