import React, { useContext, useEffect } from "react"
import { CartIcon } from "../Icons"
import DefaultOutlineButton from "../Button/DefaultOutlineButton"
import { Context } from "../context"
import { navigate } from "gatsby"
import styled from "styled-components"

const AddCartButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const AddToCart = ({ product }) => {
  const { cartData, setCartData } = useContext(Context)

  const addToCart = () => {
    let currentCartData = cartData?.map(cartItem => cartItem)
    if (currentCartData?.length > 0) {
      let found = cartData.find(cartItem => cartItem.id === product.id)
      console.log("found =", found)
      if (found) {
        currentCartData = currentCartData.map(cartItem =>
          cartItem.id === product.id
            ? { ...cartItem, count: cartItem.count + 1 }
            : cartItem
        )
      } else {
        currentCartData.push({ ...product, count: 1 })
      }
    } else {
      currentCartData.push({ ...product, count: 1 })
    }
    setCartData(currentCartData)
    navigate("/cart")
  }
  useEffect(() => {
    console.log("added new product card component=", cartData)
    localStorage.setItem("cartData", JSON.stringify(cartData))
  }, [cartData, setCartData])
  return (
    <AddCartButton onClick={() => addToCart()} className="btn-block">
      <DefaultOutlineButton icon={<CartIcon />} text="Add to Cart" />
    </AddCartButton>
  )
}

export default AddToCart
