import React from 'react'
import styled from "styled-components"
const Badge = styled.span`    
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    padding: 6px 24px;
    color: #666;
    cursor: pointer;
    display: inline-block;
    margin:6px;
    border-radius:100px;
    transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out;
`

const BadgeList = (props) => {
    const { badgeText } = props;    
    return (
        <Badge className="badge">{badgeText}</Badge>    
    )
}
export default BadgeList
