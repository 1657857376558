import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { v4 } from 'uuid';
import BreakpointUp from "../../components/Media/BreakpointUp"


export const ListColorBorad = styled.ul`	
	list-style:none;
	margin:0 -5px;
	padding:0;
`
export const ColorItem = styled.li`	
	display: inline-block;
	padding:0 5px;
	margin-bottom: 15px;
`
export const ColorCode= styled.span`	
	background: ${props=>props.bg};	
	display: block;
    margin-bottom: 2px;
    border: 4px solid #F2F4F9;
    width: 80px;
    height: 30px;
	${BreakpointUp.sm`     
		width: 100px;		
		height: 34px;
    `}
`
export const ColorName= styled.span`	
	display:block;	
	color:#666;
	font-weight:500;
	text-align:center;
	font-size:12px;
	line-height:20px;
	${BreakpointUp.sm`     
		font-size:14px;
		line-height:24px;
	`}
`

const ColorBoard = () => {
	const allColors = useStaticQuery(
		graphql`
			query colorsQuery {
				allContentfulBuildingColor {
			    edges {
			      node {
			        name
			        colorCode
			      }
			    }
			  }
			}
	  `
	);
	return(	
		<ListColorBorad>
			{
				allColors.allContentfulBuildingColor.edges.map(item => (
					<ColorItem key={v4()}>
						<ColorCode bg={item.node.colorCode}></ColorCode>								
						<ColorName>{item.node.name}</ColorName>
					</ColorItem>
				))
			}
		</ListColorBorad>					
	)
}


export default ColorBoard